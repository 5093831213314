<template>
    <div>
        <div v-if="!token">
            <van-nav-bar
                title="订单详情"
                left-text="返回"
                left-arrow
                @click-left="goBack"
                />
        </div>
        <div style="margin:20px 0px;">
            <van-steps :active="transactionStatusConver(formData.orderStatus)">
                <van-step v-for="(item,index) in $enum.userOrderStatus" :key="index">{{$enum.userOrderStatus[index]}}</van-step>
            </van-steps>
        </div>
        <van-form >            
            <van-field label="提交人" v-if="formData.providerName" :value="formData.providerName" readonly />
            <van-field label="姓名" :value="formData.name" readonly />
            <van-field label="联系方式" :value="formData.mobile" readonly />
            <van-field label="地址" :value="formData.address" readonly />
            <van-field label="车牌号" :value="formData.licensePlate" readonly />
            <van-field label="品牌型号" :value="formData.model" readonly />
            <van-field label="车辆类型" :value="formData.vechicleTypeName" readonly />
            <van-field label="能否驾驶" v-if="!token" :value="formData.canDrive?'能':'否'" readonly />
            <van-field label="是否有违章" v-if="!token" :value="formData.hasViolation?'有':'无'" readonly />
            <van-field label="报废厂电话" v-if="!token" :value="formData.agentMobile" readonly />
            <van-field label="整备质量" v-if="!token" :value="formData.autoWeight" readonly />
            <!-- <van-field label="系统估价" v-if="!token" :value="formData.customerPrice" readonly /> -->
            <!-- <van-field label="成交价" v-if="!token" :value="formData.scrapPrice" readonly /> -->
            <van-field label="创建时间" :value="formData.createTime" readonly />
            <van-field  readonly label="行驶证照片">
                <template #input>
                    <img v-for="(img,index) in listenPhotos" class="form_img" @click="imgPreview(img)" :key="index" :src="$util.loadTxImg(img.fileUrl)" />
                </template>
            </van-field>
            <van-field v-if="trailerPhotos.length"  readonly label="现场拖车照片">
                <template #input>
                    <img v-for="(img,index) in trailerPhotos" class="form_img" @click="imgPreview(img)" :key="index" :src="$util.loadTxImg(img.fileUrl)" />
                </template>
            </van-field>
            <van-field  readonly label="其它照片">
                <template #input>                    
                     <img class="form_img" v-if="formData.aboutImg" @click="img_show=true;"   :src="$cosImg(formData.aboutImg)" />
                    <van-image-preview v-model="img_show" :images="fileList" @change="onChange">
                         <template v-slot:index>第{{ index }}页</template>
                    </van-image-preview>
                </template>
            </van-field>
        </van-form>
        <div style="height:10vh;"></div>
    </div>
</template>
<script>
import { Button,ImagePreview,Step,Steps,NavBar,Field,Form  } from 'vant';
import {getShareOrder,getOrderDetail} from '../../api/provider'
export default({
    name:'detail',
    components:{
            [Button.name]: Button,
            [Form.name]: Form,
            [ImagePreview.Component.name]: ImagePreview.Component,
            [Step.name]: Step,
            [Steps.name]: Steps,
            [NavBar.name]: NavBar,
            [Field.name]: Field,
    },
    data() {
        return {
            token:'',
            index:0,
            img_show:false,
            userOrderStatus:'',
            go_url:'',
            go_active:'',
            fileList:[],
            id:this.$route.query.id,         
            formData:{
                "collectionId": 0,
                "name": "",
                "mobile": "",
                "address": "",
                "mileAge": "",
                "autoAge": 0,
                "province": 0,
                "city": 0,
                "visitTime": "2021-06-04T08:07:41.600Z",
                "country": 0,
                "provinceName": "",
                "cityName": "",
                "countryName": "",
                "licensePlate": "",
                "model": "",
                "scrapType": "",
                "canDrive": true,
                "hasViolation": true,
                "agentId": 0,
                "orderStatus": 0,
                "aboutImg": "",
                "agentMobile": "",
                "attachInputs": [
                    {
                    "fileUrl": "",
                    "useFor": 0
                    }
                ],
                "orderId": 0
            },
            listenPhotos:[],
            trailerPhotos:[],
        }
    },
    methods:{
        imgPreview(img){
            ImagePreview([this.$util.loadTxImg(img.fileUrl)]);
        },
        transactionStatusConver(status){
            if((status == 6 || status == 4) && this.formData.transactionStatus == 2){
                return 4;
            }
            switch(status){
                case 0:return 0;
                case 10:return 1;
                case 20:
                case 30:
                case 40:return 2;
                case 50:return 3;
                case 70:return 4;
                case 90:return 5;
            }
        },
        goBack(){
            this.$router.replace({path:(this.go_url||'/mIndex')});
        },
        initTableData(){
            var body = {
                id:this.id
            }
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
            getOrderDetail(body).then(res=>{
                this.$toast.clear();
                this.formData = res;     
                this.listenPhotos = res.attachInputs.filter(a=>a.useFor==0||a.useFor == 7);
                this.trailerPhotos = res.attachInputs.filter(a=>a.useFor==8||a.useFor == 9);
                this.fileList = [this.$cosImg(this.formData.aboutImg)];
            });
        },
        initShareData(){
            var body = {
                key:this.token
            }
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
            getShareOrder(body).then(res=>{
                this.$toast.clear();
                this.formData = res;     
                this.listenPhotos = res.attachInputs.filter(a=>a.useFor==0||a.useFor == 7);
                this.trailerPhotos = res.attachInputs.filter(a=>a.useFor==8||a.useFor == 9);
                this.fileList = [this.$cosImg(this.formData.aboutImg)];
            });
        },
         onChange(index) {
            this.index = index;
        },
    },
    mounted(){
        if(this.token){
            // this.formData = {}
            this.initShareData();
        }else{
            this.initTableData();
        }
    },
    created(){
        this.go_url = this.$route.query.go_url;
        this.token = this.$route.query.key;
    }
})
</script>
<style scoped>
.form_img{
    width: 80px;
    height: 80px;
}
</style>